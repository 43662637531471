import {
  Box,
  Button,
  ComboboxData,
  ComboboxItem,
  Group,
  InputWrapper,
  MultiSelect,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconBellCheck, IconChartBar, IconSend } from "@tabler/icons-react"
import _ from "lodash"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

import {
  alertCreationSchema,
  AlertsRequests,
  AlertsResponses,
} from "@costory/types/endpoints/alerts"
import { Filters } from "@costory/types/filters"
import {
  Alert,
  AlertPeriod,
  Currency,
  VirtualDimension,
} from "@costory/types/prisma-client"

import { ALL_VALUES } from "@costory/shared/const"
import { computePresetDates } from "@costory/shared/utils/filters"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { NoVirtualDimensionsPage } from "@costory/front/pages/Alerts/NoVirtualDimensionsPage"
import { AlertLevels } from "@costory/front/pages/Alerts/types"
import { ExplorerMainChart } from "@costory/front/pages/Explorer/ExplorerMainChart"
import { useAlertFormDataQuery } from "@costory/front/queries/alerts"
import { PropsWithData } from "@costory/front/utils/propsWithData"

import { LimitInputCard } from "./LimitInputCard" // Extract LimitInputCard separately

export type AlertFormData = AlertsRequests.AlertCreation
type ComboboxItemWithProps<T> = ComboboxItem & T

interface AlertFormProps extends PropsWithData<AlertsResponses.AlertFormData> {
  initialValues: Partial<Alert>
  onSave: (values: AlertFormData) => Promise<void>
  mode?: "create" | "edit"
}

export const AlertForm: FC<Omit<AlertFormProps, "data">> = (props) => {
  const query = useAlertFormDataQuery()
  return (
    <QueryWrapper query={query}>
      {({ data }) => <_AlertForm data={data} {...props} />}
    </QueryWrapper>
  )
}

const _AlertForm: FC<AlertFormProps> = ({
  data,
  initialValues: { businessMetricId, ...initialValues },
  onSave,
  mode = "create",
}) => {
  const {
    virtualDimensions,
    businessMetrics,
    availableOwners,
    existingAlerts,
    existingSlackChannels,
  } = data

  const isEditMode = mode === "edit"

  const [selectedVirtualDimension, setSelectedVirtualDimension] =
    useState<VirtualDimension>()

  const existingAlertsHashes = useMemo(
    () =>
      existingAlerts.map(
        (alert) => alert.virtualDimensionId + alert.virtualDimensionValue,
      ),
    [existingAlerts],
  )

  const doesAlertAlreadyExist = useCallback(
    (virtualDimensionId: string, virtualDimensionValue: string) => {
      return existingAlertsHashes.includes(
        virtualDimensionId + virtualDimensionValue,
      )
    },
    [existingAlertsHashes],
  )

  const getVirtualDimension = useCallback(
    (id: string) =>
      virtualDimensions.find((virtualDimension) => virtualDimension.id === id),
    [virtualDimensions],
  )

  const form = useForm<AlertFormData>({
    // @ts-expect-error that's because of the partial initial values
    initialValues: {
      currency: Currency.USD,
      period: AlertPeriod.MONTHLY,
      businessMetricId: businessMetricId ?? "cost",
      slackChannelIds: [],
      ...initialValues,
    },
    validate: zodResolver(alertCreationSchema),
  })
  useEffect(() => {
    setSelectedVirtualDimension(
      getVirtualDimension(form.values.virtualDimensionId),
    )
  }, [form.values.virtualDimensionId, getVirtualDimension])

  form.watch("virtualDimensionId", () => {
    // @ts-expect-error mantine doesn't allow to set null value
    form.setFieldValue("virtualDimensionValue", null)
  })

  const virtualDimensionsSelectItems: ComboboxData = useMemo(
    () =>
      virtualDimensions.map((virtualDimension) => ({
        value: virtualDimension.id,
        label: virtualDimension.name,
      })),
    [virtualDimensions],
  )

  const businessMetricsSelectItems: ComboboxData = useMemo(
    () =>
      businessMetrics.map((businessMetric) => ({
        value: businessMetric.id,
        label: businessMetric.name,
      })),
    [businessMetrics],
  )

  const currencySelectItems: ComboboxData = useMemo(
    () =>
      Object.values(Currency).map((value) => ({
        value,
        label: value,
      })),
    [],
  )

  const periodSelectItems: ComboboxData = useMemo(
    () =>
      Object.values(AlertPeriod).map((value) => ({
        value,
        label: _.upperFirst(value.toLowerCase()),
      })),
    [],
  )

  const ownerSelectItems: ComboboxData = useMemo(
    () =>
      availableOwners.map((owner) => ({
        value: owner.id,
        label: _.startCase(owner.firstName + " " + owner.lastName),
      })),
    [availableOwners],
  )

  const virtualDimensionValueSelectItems = useMemo(() => {
    if (!selectedVirtualDimension) {
      return []
    }

    return [ALL_VALUES, ...selectedVirtualDimension.values].map(
      (virtualDimensionValue): ComboboxItemWithProps<{ exists: boolean }> => ({
        value: virtualDimensionValue,
        label:
          virtualDimensionValue === ALL_VALUES
            ? "All values"
            : virtualDimensionValue,
        exists: (() => {
          const exists = doesAlertAlreadyExist(
            selectedVirtualDimension.id,
            virtualDimensionValue,
          )
          return exists
        })(),
      }),
    )
  }, [selectedVirtualDimension, doesAlertAlreadyExist])

  // Compute filters only if all required fields are filled
  const filters: Filters | undefined =
    selectedVirtualDimension &&
    form.values.virtualDimensionValue &&
    form.values.businessMetricId &&
    form.values.period &&
    form.values.currency
      ? {
          limit: 3,
          aggBy: "Week",
          metricId: form.values.businessMetricId,
          groupBy: selectedVirtualDimension.bqName,
          currency: form.values.currency,
          ...computePresetDates(
            form.values.period === "MONTHLY"
              ? "LAST_12_MONTHS"
              : form.values.period === "WEEKLY"
                ? "LAST_3_MONTHS"
                : "LAST_WEEK",
          ),
          whereClause: {
            combinator: "and",
            rules: [
              {
                field: selectedVirtualDimension.bqName,
                operator: "in",
                valueSource: "value",
                value:
                  form.values.virtualDimensionValue === ALL_VALUES
                    ? [...selectedVirtualDimension.values]
                    : [form.values.virtualDimensionValue],
              },
            ],
          },
        }
      : undefined

  const referenceLines = [
    {
      y: form.values.redLimit,
      stroke: "red",
      strokeWidth: 2,
    },
    {
      y: form.values.orangeLimit,
      stroke: "orange",
      strokeWidth: 2,
    },
    {
      y: form.values.yellowLimit,
      stroke: "yellow",
      strokeWidth: 2,
    },
  ].filter((line) => !!line)

  if (virtualDimensions.length === 0) {
    return <NoVirtualDimensionsPage />
  }

  return (
    // @ts-expect-error that's because of the partial initial values
    <form onSubmit={form.onSubmit(onSave)}>
      <Stack>
        <InputWrapper label="Alert name">
          <TextInput
            {...form.getInputProps("name")}
            placeholder="Ex: Production environnment monthly cost limit"
          />
        </InputWrapper>

        <Group>
          <Stack flex={1}>
            <Stack>
              <Title>What</Title>
              <Group>
                <InputWrapper label="Select a virtual dimension">
                  <Tooltip
                    disabled={!isEditMode}
                    label="This value cannot be edited, please create a new alert"
                  >
                    <Select
                      {...form.getInputProps("virtualDimensionId")}
                      data={virtualDimensionsSelectItems}
                      placeholder="Ex: Environnments"
                      searchable
                      disabled={isEditMode}
                    />
                  </Tooltip>
                </InputWrapper>

                <InputWrapper label="Select a value">
                  <Tooltip
                    disabled={!isEditMode}
                    label="This value cannot be edited, please create a new alert"
                  >
                    <Select
                      data={virtualDimensionValueSelectItems}
                      placeholder="Ex: Production"
                      disabled={
                        isEditMode ||
                        virtualDimensionValueSelectItems.length === 0
                      }
                      renderOption={(item) => (
                        <Group justify="space-between" flex={1}>
                          <Box>{item.option.label}</Box>
                          {(
                            item.option as ComboboxItemWithProps<{
                              exists: boolean
                            }>
                          ).exists && (
                            <Tooltip label="There is already an alert for this value">
                              <IconBellCheck color="gray" />
                            </Tooltip>
                          )}
                        </Group>
                      )}
                      {...form.getInputProps("virtualDimensionValue")}
                    />
                  </Tooltip>
                </InputWrapper>
              </Group>
            </Stack>

            <Stack>
              <Title>When</Title>
              <Group>
                <InputWrapper label="Select a period">
                  <Tooltip
                    disabled={!isEditMode}
                    label="This value cannot be edited, please create a new alert"
                  >
                    <Select
                      {...form.getInputProps("period")}
                      data={periodSelectItems}
                      placeholder="Ex: MONTHLY"
                      disabled={isEditMode}
                    />
                  </Tooltip>
                </InputWrapper>
                <InputWrapper label="Select a currency">
                  <Tooltip
                    disabled={!isEditMode}
                    label="This value cannot be edited, please create a new alert"
                  >
                    <Select
                      {...form.getInputProps("currency")}
                      data={currencySelectItems}
                      placeholder="Ex: USD"
                      disabled={isEditMode}
                    />
                  </Tooltip>
                </InputWrapper>
                <InputWrapper label="Select a metric">
                  <Tooltip
                    disabled={!isEditMode}
                    label="This value cannot be edited, please create a new alert"
                  >
                    <Select
                      {...form.getInputProps("businessMetricId")}
                      data={businessMetricsSelectItems}
                      placeholder="Ex: Cost"
                      disabled={isEditMode}
                    />
                  </Tooltip>
                </InputWrapper>
              </Group>

              <Stack>
                <LimitInputCard
                  level={AlertLevels.Red}
                  filters={filters}
                  // @ts-expect-error that's because of the partial initial values
                  form={form}
                  onClickSuggestValue={(value) =>
                    form.setFieldValue("redLimit", value)
                  }
                />
                <LimitInputCard
                  level={AlertLevels.Orange}
                  filters={filters}
                  // @ts-expect-error that's because of the partial initial values
                  form={form}
                  onClickSuggestValue={(value) =>
                    form.setFieldValue("orangeLimit", value)
                  }
                />
                <LimitInputCard
                  level={AlertLevels.Yellow}
                  filters={filters}
                  // @ts-expect-error that's because of the partial initial values
                  form={form}
                  onClickSuggestValue={(value) =>
                    form.setFieldValue("yellowLimit", value)
                  }
                />
              </Stack>
            </Stack>
          </Stack>

          <Group flex={1}>
            <Paper flex={1} px={32} py={24}>
              <Stack h={400} justify="center">
                {filters ? (
                  <ExplorerMainChart
                    filters={filters}
                    referenceLines={referenceLines}
                  />
                ) : (
                  <Stack align="center">
                    <IconChartBar size={64} strokeWidth={1} />
                    <Text size="xl" ta="center">
                      Configure the alert to see the preview
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Paper>
          </Group>
        </Group>
        <Stack>
          <Title>Who</Title>

          <InputWrapper label="Select an owner">
            <Select
              {...form.getInputProps("ownerId")}
              data={ownerSelectItems}
              placeholder="Type to search"
              searchable
            />
          </InputWrapper>
          <InputWrapper label="Select slack channels">
            <MultiSelect
              {...form.getInputProps("slackChannelIds")}
              data={existingSlackChannels}
              placeholder="Type to search"
              searchable
            />
          </InputWrapper>
        </Stack>
        <Button
          type="submit"
          style={{ alignSelf: "flex-end" }}
          leftSection={<IconSend />}
          size="md"
        >
          {isEditMode ? "Save" : "Create"} alert
        </Button>
      </Stack>
    </form>
  )
}
