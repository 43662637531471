import { ActionIcon, Box, Group, Progress, Text, Tooltip } from "@mantine/core"
import { modals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import {
  IconAlertCircleFilled,
  IconAlertHexagonFilled,
  IconAlertTriangleFilled,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react"
import _ from "lodash"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { Link } from "react-router-dom"

import { AlertsResponses } from "@costory/types/endpoints/alerts"

import { ALL_VALUES } from "@costory/shared/const"

import { useDeleteAlertMutation } from "@costory/front/queries/alerts"
import { formatNumber, formatToLongName } from "@costory/front/utils/format"
import { getDefaultTableOptions } from "@costory/front/utils/table"

export default function AlertTable({
  alertData,
}: {
  alertData: AlertsResponses.DetailedAlert[]
}) {
  const { mutateAsync: deleteAlert } = useDeleteAlertMutation()

  const columnHelper = createMRTColumnHelper<AlertsResponses.DetailedAlert>()

  const onConfirmDeleteAlert = async (id: string) => {
    try {
      await deleteAlert(id)
      showNotification({ title: "Success", message: "Alert deleted" })
    } catch {
      showNotification({
        title: "Error",
        message: "Failed to delete alert",
        color: "red",
      })
    }
  }

  // Define the columns for the table
  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor((row) => row.computationResults[0]?.alertStatus, {
      header: "Progress",
      size: 150,
      enableColumnActions: true,
      Cell: ({ row: { original: alert } }) => {
        const statusColor =
          alert.currentStatus === null
            ? "gray"
            : alert.currentStatus.toLowerCase()

        return (
          <Group justify="space-between" wrap="nowrap">
            <Progress.Root
              h={20}
              w={70}
              bg={`${statusColor}.2`}
              styles={{ root: { borderRadius: 8 } }}
            >
              <Progress.Section
                value={alert.progress}
                color={`${statusColor}.5`}
              />
            </Progress.Root>
            <Text>{`${alert.progress.toFixed(0)}%`}</Text>
          </Group>
        )
      },
    }),
    columnHelper.display({
      header: "Last periods",
      size: 200,
      enableColumnActions: true,
      Cell: ({ row: { original: alert } }) => {
        const barWidth = 150

        return (
          <Group h={32} gap={2} w={barWidth}>
            {alert.lastTriggers
              //  .slice() // to make a copy and avoid reverse being mutable
              .reverse()
              .map((trigger, index) => (
                <Tooltip label={trigger.dateLabel} key={index}>
                  <Box
                    bg={
                      trigger.status !== null
                        ? `${trigger.status.toLowerCase()}.5`
                        : "lightgray"
                    }
                    style={{
                      width: 16,
                      height: "100%",
                      ...(index === 0 && { borderRadius: "4px 0 0 4px" }),
                      ...(index === alert.lastTriggers.length - 1 && {
                        borderRadius: "0px 4px 4px 0px",
                      }),
                    }}
                  />
                </Tooltip>
              ))}
          </Group>
        )
      },
    }),
    columnHelper.accessor("virtualDimension.name", {
      header: "Virtual Dimension",
    }),
    columnHelper.accessor(
      (row) =>
        row.virtualDimensionValue === ALL_VALUES
          ? "All values"
          : row.virtualDimensionValue,
      {
        header: "Value",
        size: 100,
      },
    ),
    columnHelper.accessor(
      (row) => formatToLongName(row.owner.firstName, row.owner.lastName),
      {
        header: "Owner",
      },
    ),
    columnHelper.accessor("yellowLimit", {
      enableColumnActions: false,
      header: "yellow limit",
      Header: <IconAlertTriangleFilled color="yellow" />,
      size: 70,
      Cell: ({
        row: {
          original: { yellowLimit, currency },
        },
      }) => formatNumber(yellowLimit || 0, "currency", undefined, currency),
    }),
    columnHelper.accessor("orangeLimit", {
      enableColumnActions: false,
      header: "orange limit",
      Header: <IconAlertHexagonFilled color="orange" />,
      size: 70,
      Cell: ({
        row: {
          original: { orangeLimit, currency },
        },
      }) => formatNumber(orangeLimit || 0, "currency", undefined, currency),
    }),
    columnHelper.accessor("redLimit", {
      enableColumnActions: false,
      header: "red limit",
      Header: <IconAlertCircleFilled color="red" />,
      size: 70,
      Cell: ({
        row: {
          original: { redLimit, currency },
        },
      }) => formatNumber(redLimit || 0, "currency", undefined, currency),
    }),

    columnHelper.accessor((row) => _.upperFirst(row.period.toLowerCase()), {
      header: "Period",
      size: 100,
    }),
    columnHelper.display({
      header: "Actions",
      enableColumnActions: false,
      Cell: ({
        row: {
          original: { id: alertId },
        },
      }) => (
        <Group>
          <ActionIcon
            component={Link}
            to={`${alertId}/edit`}
            bg="primary"
            color="white"
          >
            <IconEdit />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              modals.openConfirmModal({
                title: "Are you sure?",
                confirmProps: { color: "red" },
                labels: { cancel: "Cancel", confirm: "Delete" },
                onConfirm: () => onConfirmDeleteAlert(alertId),
              })
            }}
            bg="red"
            color="white"
          >
            <IconTrash />
          </ActionIcon>
        </Group>
      ),
    }),
  ]

  const table = useMantineReactTable<AlertsResponses.DetailedAlert>({
    columns,
    data: alertData,
    ...getDefaultTableOptions(),
  })

  return <MantineReactTable table={table} />
}
