import {
  IconActivityHeartbeat,
  IconBriefcase,
  IconChartBarPopular,
  IconDatabase,
  IconFileAnalytics,
  IconFolder,
  IconHome,
  IconNorthStar,
  IconPackage,
  IconSettings,
  IconZoomMoney,
} from "@tabler/icons-react"
import { ComponentType } from "react"

import { Permissions } from "@costory/front/utils/permissions"

export type SidebarItem = {
  path?: string
  name: string
  icon: ComponentType
  position: "top" | "bottom"
  links?: SidebarItem[]
  role: string
  tooltip?: string
}

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    path: "home",
    name: "Home",
    icon: IconHome,
    position: "top",
    role: Permissions.Member,
  },
  {
    path: "reports",
    name: "Reports",
    icon: IconFolder,
    position: "top",
    role: Permissions.Member,
  },
  {
    path: "explorer",
    name: "Explorer",
    icon: IconChartBarPopular,
    position: "top",
    role: Permissions.Member,
    tooltip: "Explore multi-cloud spend, correlate it to your business growth",
  },
  {
    path: "waterfall",
    name: "Cost Waterfall",
    icon: IconPackage,
    position: "top",
    role: Permissions.Member,
    tooltip: "Understand the contribution factors of cost changes",
  },
  {
    path: "insights",
    name: "Insights",
    icon: IconNorthStar,
    position: "top",
    role: Permissions.Member,
    tooltip: "Have an overall view of your spend",
  },
  {
    path: "virtual-dimensions",
    name: "Virtual Dimensions",
    icon: IconBriefcase,
    position: "top",
    role: Permissions.Member,
    tooltip: "Your FinOps Rosetta Stone",
  },
  {
    path: "data-health",
    name: "Data Health",
    icon: IconActivityHeartbeat,
    position: "top",
    role: Permissions.Member,
    tooltip: "Monitor data freshness",
  },
  {
    name: "Settings",
    icon: IconSettings,
    role: Permissions.Member,
    position: "top",
    links: [
      {
        path: "datasources",
        name: "Datasources",
        icon: IconDatabase,
        position: "top",
        role: Permissions.Member,
        tooltip: "Connect data sources",
      },
      {
        path: "metrics",
        name: "Metrics",
        icon: IconZoomMoney,
        position: "top",
        role: Permissions.Member,
        tooltip: "Connect data sources",
      },
      // {
      //   path: "slack-integration",
      //   name: "Slack Integration",
      //   icon: IconBrandSlack,
      //   position: "top",
      //   role: Permissions.Admin,
      //   tooltip: "Connect to your slack account",
      // },
      {
        path: "labels-explorer",
        name: "Labels Explorer",
        icon: IconFileAnalytics,
        position: "bottom",
        role: Permissions.Member,
      },
      // {
      //   path: "tags",
      //   name: "Tags",
      //   icon: IconTag,
      //   position: "top",
      //   role: Permissions.Admin,
      //   tooltip: "Tag management",
      // },
    ],
  },
]
