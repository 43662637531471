import {
  OrganizationSwitcher,
  RedirectToSignIn,
  UserButton,
  useUser,
} from "@clerk/clerk-react"
import {
  Alert,
  AppShell,
  Container,
  LoadingOverlay,
  Stack,
  NavLink,
  Group,
} from "@mantine/core"
import { IconHome2 } from "@tabler/icons-react"
import { Navigate, Outlet } from "react-router-dom"

import { UserProvider } from "@costory/front/components/auth/UserContext"
import { useAuthState } from "@costory/front/queries/auth"

export const AuthGuard = () => {
  const { isSignedIn, isLoading, user, isError } = useAuthState()
  if (isError) {
    return <Navigate to="org-selection" />
  }
  if (isLoading) return <LoadingOverlay visible />
  if (!isSignedIn) return <RedirectToSignIn />
  return (
    <UserProvider user={user}>
      <Outlet />
    </UserProvider>
  )
}

export const AuthGuardWithoutOrganization = () => {
  const { isLoaded, isSignedIn } = useUser()
  if (!isLoaded) return <LoadingOverlay visible />
  if (!isSignedIn) return <RedirectToSignIn redirectUrl="/" />
  return (
    <AppShell layout="alt">
      <AppShell.Main>
        <Container fluid mih="calc(100dvh)">
          <Stack justify="center" align="center">
            <Alert variant="white" color="gray">
              Welcome to Costory, please choose an organization:
            </Alert>

            <Group>
              <OrganizationSwitcher
                hidePersonal
                afterSelectOrganizationUrl="/home"
              />
              <UserButton />
            </Group>
            <Group>
              <NavLink
                style={{ align: "center" }}
                href="/home"
                label="Access this organization"
                leftSection={<IconHome2 size="1rem" stroke={1.5} />}
              />
            </Group>
          </Stack>
        </Container>
      </AppShell.Main>
    </AppShell>
  )
}
