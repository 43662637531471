import { Select, Text } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"

import { IntegrationResponses } from "@costory/types/endpoints/integration"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { SlackButton } from "@costory/front/pages/Integration/components/SlackButton"

export const SlackIntegration = ({
  data,
}: {
  data: IntegrationResponses.slackCheck
}) => {
  return (
    <div>
      <h1>Integrations</h1>
      {data.channels ? (
        <>
          <Text size="md">Slack integration is enabled</Text>

          <Select placeholder="Channels" data={data.channels} />
        </>
      ) : (
        <SlackButton url={data.url!} />
      )}
    </div>
  )
}

export const IntegrationPage = () => {
  const slackQuery = useQuery({
    queryKey: ["slack_check"],
    queryFn: async () => {
      const response =
        await apiClient.get<IntegrationResponses.slackCheck>("/slack/check")
      return response.data
    },
  })

  return (
    <QueryWrapper query={slackQuery}>
      {({ data }) => <SlackIntegration data={data} />}
    </QueryWrapper>
  )
}
