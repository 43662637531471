import { ActionIcon, Group, Text } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import {
  IconCaretDownFilled,
  IconCaretRightFilled,
  IconFolder,
} from "@tabler/icons-react"
import { MouseEventHandler, useEffect } from "react"

import { ROOT_FOLDER_ID } from "@costory/shared/const"

import { FolderNodeProps } from "@costory/front/components/FileExplorer/TreeExplorer"
import { prefetchFolderContent } from "@costory/front/queries/folders"

export const DefaultFolderNode = ({
  expanded,
  selected,
  node: {
    value,
    label,
    children,
    nodeProps: { onSelect, onDoubleClick, ...nodeProps },
  },
  elementProps,
}: FolderNodeProps) => {
  const { ref, hovered } = useHover()

  useEffect(() => {
    children?.map((child) => prefetchFolderContent(child.value))
  }, [children])

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    onDoubleClick?.(e)
  }

  return (
    <Group
      ref={ref}
      h={32}
      {...elementProps}
      justify="space-between"
      onClick={onSelect}
      onDoubleClick={handleDoubleClick}
      styles={{
        root: {
          borderRadius: 8,
        },
      }}
      bg={selected ? "primary.1" : hovered ? "gray.1" : "transparent"}
      mb={4}
    >
      <Group gap={2} justify="space-between">
        <Group gap={8}>
          <ActionIcon
            disabled={value === ROOT_FOLDER_ID}
            bg="transparent"
            onClick={nodeProps.onClickExpand}
            size="sm"
            color="black"
          >
            {expanded || nodeProps.shouldShowCreateFolderInput ? (
              <IconCaretDownFilled />
            ) : (
              <IconCaretRightFilled />
            )}
          </ActionIcon>
          <IconFolder size={20} />
        </Group>
        <Text mt={3} fw="normal" c="gray.7">
          {label}
        </Text>
      </Group>
    </Group>
  )
}
