import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

import { Modal, Paper, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { openConfirmModal } from "@mantine/modals"
import { useEffect, useState } from "react"
import { Layout, Responsive, WidthProvider } from "react-grid-layout"
import { useBlocker, useNavigate } from "react-router-dom"

import {
  DashboardRequests,
  DashboardResponses,
} from "@costory/types/endpoints/dashboard"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import {
  AddWidget,
  AddWidgetForm,
} from "@costory/front/components/dashboard/AddWidget"
import { DashboardActionBar } from "@costory/front/components/dashboard/DashboardActionBar"
import { DashboardWidget } from "@costory/front/components/dashboard/DashboardWidget"
import {
  CHART_TYPE_WIDGET_CONFIG,
  ChartType,
} from "@costory/front/components/dashboard/constants"
import { EmptyDashboard } from "@costory/front/pages/Dashboards/EmptyDashboard"
import {
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
} from "@costory/front/queries/dashboard"

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export type Widget = Layout & {
  title: string
  viewId: string
  chartType: ChartType
}

type Props = {
  dashboard?: DashboardResponses.GetDashboard
  savedViews: SavedViewsResponses.SavedView[]
}

export const DashboardLayout = ({ dashboard, savedViews }: Props) => {
  const isExistingDashboard = !!dashboard
  const [isEditing, setIsEditing] = useState(dashboard === undefined)
  const [isOpen, { open, close }] = useDisclosure()
  const [hoveredWidget, setHoveredWidget] = useState<string | null>(null)
  const navigate = useNavigate()
  const handleHover = (widgetId: string | null) => {
    setHoveredWidget(widgetId)
  }
  const blocker = useBlocker(() => isEditing && isExistingDashboard)

  useEffect(() => {
    if (blocker.state === "blocked") {
      openConfirmModal({
        title: "You have unsaved changes",
        children: <Text>Are you sure you want to leave ?</Text>,
        onClose: blocker.reset,
        onConfirm: blocker.proceed,
        labels: {
          cancel: "Stay",
          confirm: "Leave",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state])

  const [widgets, setWidgets] = useState<Widget[]>([])
  useEffect(() => {
    setWidgets(
      isExistingDashboard
        ? dashboard.dashboardWidgets!.map((widget, index) => ({
            i: `${index + 1}`,
            ...widget,
            ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
          }))
        : [],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard])

  const {
    mutateAsync: updateDashboard,
    isPending: isUpdateDashboardMutationPending,
  } = useUpdateDashboardMutation()
  const {
    mutateAsync: createDashboard,
    isPending: isCreateDashboardMutationPending,
  } = useCreateDashboardMutation()

  const handleCreate = async (
    payload: Omit<DashboardRequests.NewDashboard, "dashboardWidgets">,
  ) => {
    try {
      const { dashboardId } = await createDashboard({
        ...payload,
        dashboardWidgets: widgets,
      })
      navigate(`/dashboards/${dashboardId}`)
    } catch (e) {
      console.error(e)
    }
  }

  const handleUpdate = async (
    payload: Omit<DashboardRequests.UpdateDashboard, "dashboardWidgets">,
  ) => {
    await updateDashboard({
      id: dashboard!.id,
      dashboard: {
        ...payload,
        dashboardWidgets: widgets,
      },
    })
    setIsEditing(false)
  }

  const handleModify = (currentLayout: Layout[]) => {
    // setLayouts(allLayouts)
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) => {
        const matchingLayout = currentLayout.find(
          (layout) => layout.i === widget.i,
        )
        if (!matchingLayout) return widget
        return {
          ...widget,
          ...matchingLayout,
        }
      }),
    )
  }

  const handleAdd = (widgetConfig: AddWidgetForm) => {
    setWidgets((prevWidgets) => [
      ...prevWidgets,
      {
        i: `${widgetConfig.chartType}-${widgets.length + 1}`,
        x: 0,
        y: 0,
        ...widgetConfig,
        ...CHART_TYPE_WIDGET_CONFIG[widgetConfig.chartType].initial,
      },
    ])
    close()
  }

  const handleDelete = (key: string) => {
    setWidgets((prevWidgets) =>
      prevWidgets.filter((widget) => widget.i !== key),
    )
  }
  const handleEditTitle = (key: string, newTitle: string) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.i === key ? { ...widget, title: newTitle.trim() } : widget,
      ),
    )
  }

  const handleClickAddWidget = () => {
    setIsEditing(true)
    open()
  }

  const handleDiscardChanges = () => {
    setWidgets(
      dashboard!.dashboardWidgets!.map((widget, index) => ({
        i: `${index + 1}`,
        ...widget,
        ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
      })),
    )
    setIsEditing(false)
  }

  return (
    <Stack>
      <DashboardActionBar
        widgets={widgets}
        isEditing={isEditing}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onAddWidget={open}
        currentDashboard={isExistingDashboard ? dashboard : undefined}
        isExistingDashboard={isExistingDashboard}
        setIsEditing={setIsEditing}
        isCreateDashboardMutationPending={isCreateDashboardMutationPending}
        isUpdateDashboardMutationPending={isUpdateDashboardMutationPending}
        onDiscardChanges={handleDiscardChanges}
      />
      {widgets.length ? (
        <ResponsiveReactGridLayout
          style={{
            background: isEditing
              ? "var(--mantine-primary-color-2)"
              : "transparent",
          }}
          onDragStop={handleModify}
          onResizeStop={handleModify}
          preventCollision={false}
          margin={[20, 20]}
          containerPadding={[0, 0]}
          draggableHandle=".dragHandle"
          resizeHandles={isEditing ? ["sw", "nw", "se", "ne"] : []}
          cols={{ lg: 8, md: 8, sm: 8, xs: 8, xxs: 8 }}
          autoSize
        >
          {widgets.map((widget) => {
            const view = savedViews.find((view) => view.id === widget.viewId)
            if (!view) return null
            return (
              <Paper
                p={0}
                key={widget.i}
                onMouseOver={() => handleHover(widget.i)}
                onMouseOut={() => handleHover(null)}
                style={{
                  zIndex:
                    hoveredWidget === widget.i
                      ? "100"
                      : widgets.length - widgets.indexOf(widget),
                }}
                data-grid={{
                  x: widget.x,
                  y: widget.y,
                  w: widget.w,
                  h: widget.h,
                  i: widget.i,
                  ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
                  isResizable:
                    isEditing &&
                    CHART_TYPE_WIDGET_CONFIG[widget.chartType].isResizable,
                  isDraggable: isEditing,
                }}
              >
                <DashboardWidget
                  title={widget.title}
                  height={widget.h * 160}
                  savedView={view}
                  chartType={widget.chartType}
                  onDelete={() => handleDelete(widget.i)}
                  onRename={(title) => handleEditTitle(widget.i, title)}
                  isEditable={isEditing}
                />
              </Paper>
            )
          })}
        </ResponsiveReactGridLayout>
      ) : (
        <EmptyDashboard onClickAddWidget={handleClickAddWidget} />
      )}
      <Modal
        opened={isOpen}
        onClose={close}
        title="Add a new widget"
        size={1000}
      >
        <AddWidget onAdd={handleAdd} />
      </Modal>
    </Stack>
  )
}
