import { Group } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { MouseEventHandler } from "react"

import { FileListItem } from "@costory/front/components/FileExplorer/FileListItem"
import {
  DashboardLeafProps,
  SavedViewLeafProps,
} from "@costory/front/components/FileExplorer/TreeExplorer"

export const DefaultFileLeaf = ({
  node: {
    value,
    label,
    nodeProps: { onSelect, type, onDoubleClick, ...nodeProps },
  },
  selected,
  elementProps,
}: DashboardLeafProps | SavedViewLeafProps) => {
  const { ref, hovered } = useHover()

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    onDoubleClick?.(e)
  }

  return (
    <Group
      {...elementProps}
      ref={ref}
      h={32}
      justify="space-between"
      mb={4}
      bg={selected ? "primary.1" : hovered ? "gray.1" : "transparent"}
      styles={{
        root: {
          borderRadius: 8,
        },
      }}
      onClick={onSelect}
      onDoubleClick={handleDoubleClick}
      pr={8}
    >
      <FileListItem
        {...nodeProps}
        id={value}
        type={type}
        label={label as string}
      />
    </Group>
  )
}
