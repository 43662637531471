import { Stack } from "@mantine/core"
import { useForm } from "@mantine/form"
import _ from "lodash"
import { JSX, useCallback, useEffect, useMemo } from "react"
import { add, formatQuery } from "react-querybuilder"
import { useLocation } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import { AxesResponses } from "@costory/types/endpoints/axes"
import { MetricsResponses } from "@costory/types/endpoints/metrics"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { filterBarSchema, Filters } from "@costory/types/filters"
import { AggBy, Currency } from "@costory/types/prisma-client"

import {
  ALL_OTHERS,
  OTHERS,
  UNLABELLED,
} from "@costory/shared/utils/explorer_data"
import { computePresetDates } from "@costory/shared/utils/filters"

import { FilterBar } from "@costory/front/components/FilterBar"
import LiveBlocks from "@costory/front/components/Room"
import { SavedViewsSelector } from "@costory/front/components/SavedViewsSelector"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useAuthState } from "@costory/front/queries/auth"
import { useAxesQuery } from "@costory/front/queries/axes"
import { useBusinessMetricsQuery } from "@costory/front/queries/businessMetrics"
import { SavedViewRedirectPage } from "@costory/front/queries/savedViews"
import {
  DEFAULT_QUERY_BUILDER_FILTER,
  DEFAULT_QUERY_BUILDER_FILTER_RECCURING,
  formatDatasources,
  useFiltersSearchParamsBinding,
} from "@costory/front/utils/filters"

type RootProps = {
  redirectPage: SavedViewRedirectPage
  savedView?: SavedViewsResponses.SavedView
  defaultHideOneTime?: boolean
  children: (props: {
    filters: Filters
    drillDownInto: (groupBy: string, value: string, newGroupBy: string) => void
    setThreshold: (threshold: number | null) => void
  }) => JSX.Element | null
}

// The `key` is forced here because it's used to properly rerender form when saving a view
type QueryContainerProps = RootProps & { key: string }

type Props = RootProps & {
  axes: AxesResponses.Axis[]
  metrics: MetricsResponses.Metric[]
}

const _ChartContainer = ({
  axes,
  metrics,
  savedView,
  redirectPage,
  children,
  defaultHideOneTime,
}: Props) => {
  const metricsOptions = useMemo(
    () =>
      _.sortBy(metrics, (d) => Number(!("technical" in d))).map((metric) => ({
        label: metric.name,
        value: metric.id,
      })),
    [metrics],
  )
  const groupByOptions = useMemo(() => formatDatasources(axes), [axes])

  const filtersValidator = useMemo(
    () => filterBarSchema(axes.map((axis) => axis.name)),
    [axes],
  )

  const validateFilters = useCallback(
    (filters: Filters) => {
      const validatedFilters = filtersValidator.safeParse(filters)
      if (validatedFilters.success) {
        const transformedFilters = {
          ...validatedFilters.data,
          ...(filters.threshold && {
            // @ts-expect-error zod sometimes returns string here
            threshold: parseInt(filters.threshold),
          }),
          whereClause: JSON.parse(
            formatQuery(validatedFilters.data.whereClause, "json_without_ids"),
          ),
        }
        return {
          ...validatedFilters,
          data: transformedFilters as Filters,
        }
      }
      return validatedFilters
    },
    [filtersValidator],
  )

  const { urlFilters, setURLFilters } =
    useFiltersSearchParamsBinding(validateFilters)
  const location = useLocation()

  const defaultFilters = {
    limit: 10,
    aggBy: AggBy.Month,
    metricId: metricsOptions[0].value,
    groupBy: "cos_provider",
    currency: Currency.USD,
    ...computePresetDates("LAST_MONTH"),
    whereClause: defaultHideOneTime
      ? DEFAULT_QUERY_BUILDER_FILTER_RECCURING
      : DEFAULT_QUERY_BUILDER_FILTER,
  }

  const getFiltersFromSavedViewOrDefault = (
    savedView: SavedViewsResponses.SavedView | undefined,
  ) => {
    if (!savedView) {
      return defaultFilters
    }

    const validatedSavedView = validateFilters(savedView)
    if (!validatedSavedView.success) {
      return defaultFilters
    }

    return validatedSavedView.data
  }

  /**
   * Used to decide if the saved view has been touched
   */
  const referenceFilters = useMemo<Filters>(
    () => getFiltersFromSavedViewOrDefault(savedView),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [savedView],
  )

  /**
   * Used to initialize the form with the saved view, url or default filters
   */
  const initialValues = useMemo<Filters>(
    () => (urlFilters ? urlFilters : referenceFilters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleFormValuesChange = (current: Filters) => {
    const validatedFilters = validateFilters(current)
    if (validatedFilters.success) {
      setURLFilters(validatedFilters.data)
    }
  }

  const filterForm = useForm<Filters>({
    initialValues,
    onValuesChange: handleFormValuesChange,
    mode: "uncontrolled",
  })

  // Initialize filters on first render
  useEffect(() => {
    if (!urlFilters) {
      setURLFilters(referenceFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceFilters, location])

  const drillDownInto = (
    groupBy: string,
    value: string | undefined,
    newGroupBy: string,
  ) => {
    if (value === ALL_OTHERS || value === OTHERS) {
      return
    }
    if (value === "Current" || value === "Previous" || !value) {
      return
    }
    const toSet = { groupBy: newGroupBy }
    if (value == UNLABELLED) {
      filterForm.setValues({
        whereClause: add(
          urlFilters!.whereClause,
          {
            id: uuidv4(),
            field: groupBy,
            operator: "null",
            value: [],
          },
          [],
        ),
        ...toSet,
      })
    } else {
      const newRule = {
        id: uuidv4(),
        field: groupBy,
        operator: "in",
        value: [value],
      }

      const newWhereClause =
        urlFilters!.whereClause.combinator == "or" &&
        urlFilters!.whereClause.rules.length
          ? {
              combinator: "and",
              rules: [
                {
                  combinator: "or",
                  id: uuidv4(),
                  rules: [...urlFilters!.whereClause.rules],
                },
                newRule,
              ],
            }
          : add(urlFilters!.whereClause, newRule, [])

      filterForm.setValues({
        whereClause: newWhereClause,
        ...toSet,
      })
    }
  }

  const setThreshold = (threshold: number | null) => {
    filterForm.setFieldValue("threshold", threshold)
  }

  const resetTo = (filters: Filters) => {
    filterForm.setValues(filters)
  }

  return (
    <Stack gap={32}>
      <SavedViewsSelector
        handleReset={() => resetTo(referenceFilters)}
        handleSave={() => resetTo(filterForm.getValues())}
        currentView={savedView}
        redirectPage={redirectPage}
        form={filterForm}
        referenceFilters={referenceFilters}
        urlFilters={urlFilters}
      />
      <FilterBar
        form={filterForm}
        metricsOptions={metricsOptions}
        groupByOptions={groupByOptions}
      />
      {children({
        filters: filterForm.getValues(),
        drillDownInto,
        setThreshold,
      })}
    </Stack>
  )
}

export const ChartContainer = ({
  savedView,
  redirectPage,
  children,
  defaultHideOneTime,
}: QueryContainerProps) => {
  const axesQuery = useAxesQuery()
  const metricsQuery = useBusinessMetricsQuery()
  const auth = useAuthState()
  const roomId = savedView
    ? auth.user!.currentOrg.slug + ":" + savedView?.id + ":" + redirectPage
    : undefined
  return (
    <QueryWrapper query={axesQuery}>
      {({ data: axes }) => (
        <QueryWrapper query={metricsQuery}>
          {({ data: metrics }) => (
            <>
              <_ChartContainer
                axes={axes}
                metrics={metrics}
                savedView={savedView}
                redirectPage={redirectPage}
                defaultHideOneTime={defaultHideOneTime}
              >
                {(propsWithFilters) => children(propsWithFilters)}
              </_ChartContainer>
              <LiveBlocks roomId={roomId} />
            </>
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
