import { GridChartBaseProps } from "@mantine/charts"
import { Group, Select, Stack } from "@mantine/core"
import { useState } from "react"

import { Filters } from "@costory/types/filters"

import { AreaChart } from "@costory/front/components/charts/AreaChart"
import { LineChart } from "@costory/front/components/charts/LineChart"
import { StackedBarChart } from "@costory/front/components/charts/StackedBarChart"
import { getBestDrillDown } from "@costory/front/utils/columns"

// TODO: should this be used in the dashboard as well?
const EXPLORER_CHART_TYPES = ["Bar", "Line", "Area"]

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  drillDownInto?: (groupBy: string, value: string, newGroupBy: string) => void
  referenceLines?: GridChartBaseProps["referenceLines"]
}

export const ExplorerMainChart = ({
  filters,
  height,
  withLegend = true,
  drillDownInto = () => {},
  referenceLines,
}: Props) => {
  const [chartType, setChartType] = useState(EXPLORER_CHART_TYPES[0])

  return (
    <Stack h="100%">
      <Group justify="flex-end">
        <Select
          w={100}
          data={EXPLORER_CHART_TYPES}
          value={chartType}
          onChange={(value) => value && setChartType(value)}
        />
      </Group>
      {(() => {
        switch (chartType) {
          case "Line":
            return (
              <LineChart
                height={height}
                filters={filters}
                withLegend={withLegend}
              />
            )
          case "Area":
            return (
              <AreaChart
                height={height}
                filters={filters}
                withLegend={withLegend}
              />
            )
          default:
            return (
              <StackedBarChart
                height={height}
                filters={filters}
                withLegend={withLegend}
                drillDownInto={(value) =>
                  drillDownInto(
                    filters.groupBy,
                    value,
                    getBestDrillDown(filters.groupBy),
                  )
                }
                referenceLines={referenceLines}
              />
            )
        }
      })()}
    </Stack>
  )
}
